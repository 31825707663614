.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 13px 10px;
}

.App-header {
  background-color: #3bc9db;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('./images/jaf-grupo-simbolo.png');
  background-position: 50% 10%;
  background-repeat: no-repeat;
  /* background-size: cover; */
}

.App-link {
  color: #61dafb;
}

.header-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  font-family: 'poppins';
}

.titulo-modal {
  text-align: center;
  margin-bottom: 20px;
}

.descricao-modal {
  width: 100%;
}

.table-btns {
  display: flex;
  justify-content: flex-end;
}
